
import { Component, Vue, Prop, Getter } from "nuxt-property-decorator";

@Component
export default class POPUP extends Vue {
  @Getter("tenant/popup") popup: any;
  @Prop() data!: any;
  public item: any | null = null;
  public modalShow: boolean = false;
  counter: number = 0;

  mounted() {
    if (this.popup) {
      setTimeout(() => {
        if (this.getCookie("popup")) {
          return;
        }
        this.setCookie("popup", "true", 8);
        this.modalShow = true;
        this.item = this.popup[0];
      }, 5000);
    }
  }

  closeModal() {
    this.setCookie("popup", "true", 8);
    this.modalShow = false;
  }

  // scritto da AI
  setCookie(name: string, value: string, hours: number) {
    let expires = "";
    if (hours) {
      let date = new Date();
      date.setTime(date.getTime() + hours * 60 * 60 * 1000);
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + ";";
  }

  // scritto da AI
  getCookie(name: string) {
    const nameEQ = name + "=";
    const cookieArr = document.cookie.split(";");
    for (let i = 0; i < cookieArr.length; i++) {
      let cookie = cookieArr[i];
      while (cookie.charAt(0) === " ") cookie = cookie.substring(1, cookie.length);
      if (cookie.indexOf(nameEQ) === 0) return cookie.substring(nameEQ.length, cookie.length);
    }
    return null;
  }
}
