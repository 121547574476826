
import { Component, Prop, Vue } from "nuxt-property-decorator";
import { BIconPlusCircle, BIconPencilSquare } from "bootstrap-vue";

@Component({ components: { BIconPlusCircle, BIconPencilSquare } })
export default class TheDateButton extends Vue {
  @Prop() filters!: any;
  @Prop() active!: boolean;
  public modal: any = { visible: false, title: "Ricerca per data" };
  public value: any = { from: "", to: "" };

  public toggleModal() {
    this.modal.visible = !this.modal.visible;
  }

  public reset() {
    this.value.from = "";
    this.value.to = "";
  }

  public setFilterValue(arr: number[]) {
    this.$emit("set-filter-value", arr);
    this.modal.visible = false;
  }

  public makeItActive() {
    if (this.filters.data_vendita_search.length && !this.active) {
      this.$emit("make-it-active");
    }
  }
}
