
import { Component, Vue, Prop } from "nuxt-property-decorator";
//@ts-ignore
import Swiper from "@/node_modules/swiper/swiper-bundle.min";
import "swiper/swiper-bundle.min.css";

type PopupAstaItem = {
  image: PopupAstaImage;
  position: string | number | null;
  target: "_self | _blank";
  url: string;
};

type PopupAstaImage = {
  alt: string;
  title: string;
  url: string;
};

@Component
export default class POPUPASTA extends Vue {
  @Prop() data!: any;

  public items: PopupAstaItem[] = [];
  public loaded = true;

  async mounted() {
    await this.$nextTick();
    new Swiper(this.$refs.swiper, {
      grabCursor: true,
      slidesPerView: 1.2,
      spaceBetween: 10,
      breakpoints: {
        480: {
          slidesPerView: 2.2,
          spaceBetween: 20,
        },
        991: {
          slidesPerView: 3.2,
          spaceBetween: 10,
        },
        1300: {
          slidesPerView: 4.2,
          spaceBetween: 30,
        },
      },
      pagination: {
        el: ".swiper-pagination",
        type: "bullets",
        clickable: true,
      },
    });
  }

  async fetch() {
    const { data } = await this.$store.dispatch("tenant/getPopupAsta");
    this.items = [...data] as PopupAstaItem[];
    this.loaded = false;
  }

  fetchOnServer() {
    return false;
  }
}
