
import { Component, Vue, Prop } from "nuxt-property-decorator";
import POPUPASTA from "@/components/modules/POPUPASTA.vue";
import BANNERRICERCA from "@/components/modules/BANNERRICERCA.vue";
import POPUP from "@/components/modules/POPUP.vue";
import CALENDARIO from "@/components/modules/CALENDARIO.vue";
import ULTIMEINSERZIONI from "@/components/modules/ULTIMEINSERZIONI.vue";

@Component({
  components: {
    POPUPASTA,
    POPUP,
    BANNERRICERCA,
    CALENDARIO,
    ULTIMEINSERZIONI,
  },
})
export default class ExtraComponent extends Vue {
  @Prop() modules!: any;
  @Prop() banners!: any;
  @Prop() position!: string;

  public getTheme(module: string) {
    //@ts-ignore
    return module in this.$options.components ? module : "ModulesTheDefault";
  }

  public toggleShareModal(e: any) {
    this.$emit("toggle-share-modal", e);
  }

  public toggleLoginModal(e: any) {
    this.$emit("toggle-login-modal", e);
  }

  public toggleFavourite(e: any) {
    this.$emit("toggle-favourite", e);
  }

  public search() {
    this.$emit("search");
  }
}
