
import { Component, Vue, Getter, Watch } from "nuxt-property-decorator";
import { BIconBank } from "bootstrap-vue";
//@ts-ignore
import Swiper from "@/node_modules/swiper/swiper-bundle.min";
import "swiper/swiper-bundle.min.css";

@Component({ components: { BIconBank } })
export default class BANNERRICERCA extends Vue {
  public items: any[] = [];
  public isSwiper: boolean = false;
  public skeleton: Array<any> = [0, 1, 2, 3, 4, 5];
  @Getter("tenant/ivgId") ivgId: number;

  // @Watch("items", { immediate: false, deep: false })
  // onItemsChanged(val: any) {
  //   if (this.isSwiper) {
  //     setTimeout(() => {
  //       this.initSwiper();
  //     }, 10);
  //   }
  // }

  fetch() {
    this.$axios
      .get(this.$config.astaEndpoint + "bannerRicerca/get")
      .then((res) => {
        this.items = res.data.data.map((v: any) => {
          return {
            ...v,
            route: `ricerca?filter%5Btags%5D%5B0%5D=${v.tag}&filter%5Bstatus%5D%5B0%5D=In%20vendita&filter%5Bvisibile_su%5D%5B0%5D=${this.ivgId}`,
          };
        });

        if (res.data.data.length > 4) {
          this.isSwiper = true;
        }
      })
      .catch((err) => {
        throw err;
      })
      .finally(() => {
        if (this.isSwiper) {
          this.initSwiper();
        }
      });
  }

  fetchOnServer() {
    return false;
  }

  public bg(index: number) {
    const bgs = ["bg-default", "bg-secondary", "bg-crediti"];
    return bgs[index % 3];
  }

  initSwiper() {
    new Swiper(this.$refs.inEvidenzaSwiper, {
      grabCursor: true,
      centeredSlide: true,
      slidesPerView: 1.2,
      spaceBetween: 10,
      preloadImages: false,
      observer: true,
      observeParents: true,
      observeSlideChildren: true,
      lazy: {
        loadPrevNext: true,
        checkInView: true,
        enable: true,
        loadOnTransitionStart: true,
        loadPrevNextAmount: 1,
      },
      autoplay: {
        delay: 2500,
        disableOnInteraction: true,
      },
      breakpoints: {
        576: {
          slidesPerView: 2.2,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 3.2,
          spaceBetween: 10,
        },
        1300: {
          slidesPerView: 4.2,
          spaceBetween: 10,
        },
      },
      pagination: {
        el: ".swiper-pagination-calendar",
        type: "bullets",
        clickable: true,
      },
    });
  }
}
