
import { Component, Vue } from "nuxt-property-decorator";
//@ts-ignore
import Swiper from "@/node_modules/swiper/swiper-bundle.min";
import "swiper/swiper-bundle.min.css";
import { BIconChevronLeft, BIconChevronRight } from "bootstrap-vue";

@Component({ components: { BIconChevronLeft, BIconChevronRight } })
export default class ULTIMEINSERZIONI extends Vue {
  public items: any[] = [];

  async mounted() {
    await this.initSwiper();
  }

  async initSwiper() {
    await new Swiper(this.$refs.latestswiper, {
      grabCursor: true,
      centeredSlide: true,
      slidesPerView: 1.2,
      spaceBetween: 10,
      preloadImages: false,
      observer: true,
      observeParents: true,
      observeSlideChildren: true,
      autoplay: {
        delay: 2500,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
      lazy: {
        loadPrevNext: true,
        checkInView: true,
        enable: true,
        loadOnTransitionStart: true,
        loadPrevNextAmount: 1,
      },
      breakpoints: {
        576: {
          slidesPerView: 2.2,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 3.2,
          spaceBetween: 10,
        },
        1100: {
          slidesPerView: 4.2,
          spaceBetween: 10,
        },
        1300: {
          slidesPerView: 4.2,
          spaceBetween: 30,
        },
      },
      pagination: {
        el: ".swiper-pagination",
        type: "bullets",
        clickable: true,
      },
    });
  }

  async fetch() {
    const { hits } = await this.$store.dispatch("typesense/getLatest");
    this.items = hits;
  }

  fetchOnServer() {
    return false;
  }
}
