
import { Component, Vue } from "nuxt-property-decorator";
//@ts-ignore
import Swiper from "@/node_modules/swiper/swiper-bundle.min";
import "swiper/swiper-bundle.min.css";
import { BIconCalendarRange, BIconExclamationCircle } from "bootstrap-vue";
import { dateRange } from "@/types/components";

@Component({ components: { BIconCalendarRange, BIconExclamationCircle } })
export default class CALENDARIO extends Vue {
  public calendar: dateRange[] = [
    {
      from: this.$dayjs().startOf("day").unix(),
      to: this.$dayjs().endOf("day").unix(),
      name: "Oggi",
      active: false,
      visible: true,
      action: "typesense/getDataInDateRange",
      filters: ["In vendita"],
    },
    {
      from: this.$dayjs().add(1, "days").startOf("day").unix(),
      to: this.$dayjs().add(1, "days").endOf("day").unix(),
      name: "Domani",
      active: false,
      visible: true,
      action: "typesense/getDataInDateRange",
      filters: ["In vendita"],
    },
    {
      from: this.$dayjs().add(1, "days").startOf("day").unix(),
      to: this.$dayjs().add(1, "weeks").endOf("day").unix(),
      name: "Prossimi 7 giorni",
      active: false,
      visible: true,
      action: "typesense/getDataInDateRange",
      filters: ["In vendita"],
    },
    {
      from: this.$dayjs().add(1, "days").startOf("day").unix(),
      to: this.$dayjs().add(1, "months").endOf("day").unix(),
      name: "Prossimi 30 giorni",
      active: true,
      visible: true,
      action: "typesense/getDataInDateRange",
      filters: ["In vendita"],
    },
    {
      from: this.$dayjs().subtract(14, "days").unix(),
      to: this.$dayjs().endOf("day").unix(),
      name: "Appena bandite",
      active: false,
      visible: true,
      action: "typesense/getDataInDateRangeAppenaBandite",
      filters: ["In aggiornamento", "Non aggiudicato", "Venduto", "In vendita"],
    },
  ];

  public customButtonActive = false;
  public customItems: any[] = [];
  public calendarResponse: any;
  public timestampsFilter: any[] = [];
  public items: [] = [];
  public currentDateRange: dateRange = this.calendar[3];
  public filters: any = {
    data_vendita_search: [],
  };

  public loading = false;

  created() {
    if (this.filters.data_vendita_search.length) this.$store.commit("filter/setDataVenditaSearch", []);
  }

  async initSwiper() {
    await new Swiper(this.$refs.calendarswiper, {
      grabCursor: true,
      centeredSlide: true,
      slidesPerView: 1.2,
      spaceBetween: 10,
      preloadImages: false,
      observer: true,
      observeParents: true,
      observeSlideChildren: true,
      lazy: {
        loadPrevNext: true,
        checkInView: true,
        enable: true,
        loadOnTransitionStart: true,
        loadPrevNextAmount: 1,
      },
      autoplay: {
        delay: 2500,
        disableOnInteraction: true,
      },
      breakpoints: {
        576: {
          slidesPerView: 2.2,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 3.2,
          spaceBetween: 10,
        },
        1100: {
          slidesPerView: 4.2,
          spaceBetween: 10,
        },
        1300: {
          slidesPerView: 4.2,
          spaceBetween: 30,
        },
      },
      pagination: {
        el: ".swiper-pagination-calendar",
        type: "bullets",
        clickable: true,
      },
    });
  }

  async fetch() {
    this.loading = true;
    this.calendarResponse = await this.$store.dispatch("typesense/getDataInDateRange", this.currentDateRange);
    this.items = this.calendarResponse?.hits;
    this.loading = false;
    this.initSwiper();
  }

  fetchOnServer() {
    return false;
  }

  public resetButton() {
    this.calendar.forEach((element) => {
      element.active = false;
    });
  }

  public async switchActiveTo(dayRange: dateRange) {
    this.loading = true;

    this.resetButton();
    dayRange.active = true;

    this.currentDateRange = dayRange;

    this.calendarResponse = await this.$store.dispatch(dayRange.action, this.currentDateRange);
    this.items = this.calendarResponse?.hits;

    setTimeout(() => {
      this.loading = false;
    }, 350);
  }

  public async search(arr: number[] | string[]) {
    if (!arr.length) return;
    this.loading = true;
    this.resetButton();

    this.customButtonActive = true;
    this.calendarResponse = await this.$store.dispatch("typesense/getDataInDateRange", { from: arr[0], to: arr[1] });
    this.items = this.calendarResponse?.hits;
    this.filters.data_vendita_search = [...arr];

    // trasformo l'array per typesense
    if (typeof arr[0] === "number" && isNaN(arr[0])) {
      arr[0] = "<";
    } else if (typeof arr[1] === "number" && isNaN(arr[1])) {
      arr[1] = arr[0];
      arr[0] = ">";
    }
    // set the array for the vedi tutte link
    this.timestampsFilter = [...arr];

    setTimeout(() => {
      this.loading = false;
    }, 350);
  }

  public makeItActive() {
    this.loading = true;
    this.customButtonActive = true;
    setTimeout(() => {
      this.loading = false;
    }, 350);
  }

  /**
   * @description logic to search and go to the results page
   */
  public async searchAndGo() {
    // Resetto
    this.$store.commit("filter/resetFilterMutation", this.$store.getters["tenant/ivgId"]);
    this.$store.commit("filter/setStatusFilter", this.currentDateRange.filters);

    if (this.customButtonActive) {
      this.$store.commit("filter/setDataVenditaSearch", this.timestampsFilter);
    } else {
      this.$store.commit("filter/setDataVenditaSearch", [this.currentDateRange.from, this.currentDateRange.to]);
    }

    // Ricerco
    this.$emit("search");
  }
}
